<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-alert class="p-1 text-center" show variant="warning">Po dodajanju nove Vprašanja in odgovora, je potrebnih nekaj sekund, da se le ta prikaže. Če dodana vprašanje in odgovor nista vidna, poskusite osvežiti spletno stran.</b-alert>
            <faq-component :model="object" />
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="submitNewFaq()">Ustvari nov vprašanje in odgovor</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import FaqComponent from './FaqComponent.vue'
    import {BButton, BCard, BAlert, BOverlay} from 'bootstrap-vue'
    export default {
        components:{
            FaqComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay
        },
        data() {
            return {
                showLoader: false,
                object:
                    {
                        author: '',
                        answer: '',
                        question: '',
                        show: false
                    }
            }
        },
        methods: {
            async submitNewFaq() {
                this.showLoader = true
                try {
                    await this.$http.post('/api/management/v1/faq', this.object)
                    this.$printSuccess('Faq je dodan')

                    setTimeout(() => {
                        this.$router.push({name: 'faq-list'})
                    }, 1000)
                } catch (err) {
                    this.$printError('Prislo je do napake pri dodajanju')
                } finally {
                    this.showLoader = false
                }
            }
        }
    }
</script>